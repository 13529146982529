@import './vars.module.scss';

.formControlRoot {
  display: inline-block !important;
  width: auto !important;
  margin-bottom: 0 !important;
}
.formControlLabelRoot {
  display: inline-block;
  margin-right: 6px !important;
  @media screen and (max-width: 767px) {
    margin-right: 3px !important;
  }
}
.formControlLabeLabel {
  font-size: 0.8rem !important;
  color: $dark-gray;
  @media screen and (max-width: 767px) {
    font-size: 0.68rem !important;
  }
}
.tosContainer {
  margin-bottom: 1.3rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.tosLink {
  font-size: 0.8rem !important;
  color: $action-blue;
  cursor: pointer;
  &:hover {
    color: $action-blue-hover;
    text-decoration: underline;
  }
  @media screen and (max-width: 767px) {
    font-size: 0.68rem !important;
  }
}
.imageResume {
  width: 75px;
  @media screen and (max-width: 671px) {
    width: 60px;
  }
}
.imageArrow {
  width: 40px;
  padding-top: 26px;
  @media screen and (max-width: 671px) {
    width: 24px;
    padding-top: 22px;
  }
}
.imageGears {
  width: 71px;
  @media screen and (max-width: 671px) {
    width: 58px;
  }
}
.imageArchery {
  width: 75px;
  @media screen and (max-width: 671px) {
    width: 62px;
  }
}
.imagesContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 13px;
  align-items: flex-start;
  grid-area: images;
  @media screen and (min-width: 672px) {
    margin-bottom: 40px;
  }
}
.industryImagesContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 13px;
  align-items: flex-start;
  grid-area: images;
}
.resumeStepContainer {
  display: grid;
  padding: 40px 32px;
  grid-template-areas:
    'images'
    'drop'
    'link';
  @media screen and (max-width: 671px) {
    padding: 40px 16px 40px;
    grid-template-areas:
      'drop'
      'link'
      'images'
      'text';
  }
}
.industryStepContainer {
  display: grid;
  padding: 40px 32px;
  grid-template-areas:
    'images'
    'link'
    'cards';
  @media screen and (max-width: 671px) {
    padding: 40px 16px 40px;
    grid-template-areas:
      'cards'
      'link'
      'images'
      'text';
  }
}
.tosHelperTextRoot {
  left: 0;
  top: 27px !important;
  right: initial !important;
  margin-left: 26px !important;
  color: $error-color-main !important;
}
.dateHelperText {
  right: 0;
  top: 36px;
  margin-left: 26px !important;
  color: $error-color-main !important;
}
.cardsContainer {
  grid-area: cards;
  @media screen and (min-width: 672px) {
    padding-top: 26px;
  }
}
.goalsContainer {
  padding: 10px 30px 38px;
}
.imageContainer {
  width: 140px;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: 671px) {
    width: 80px;
  }
}
.imageTextContainer {
  text-align: center;
  padding-top: 10px;
  @media screen and (max-width: 671px) {
    display: none;
  }
}
.resumeFullTextContainer {
  grid-area: text;
  text-align: center;
  @media screen and (min-width: 672px) {
    display: none;
  }
}
.resumeLinkContainer {
  grid-area: link;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 34px 0 40px;
  @media screen and (min-width: 672px) {
    padding: 34px 0 4px;
    flex-direction: row;
    justify-content: center;
  }
}
.industryLinkContainer {
  grid-area: link;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 34px 0 40px;
  @media screen and (min-width: 672px) {
    padding: 11px 0 4px;
    flex-direction: row;
    justify-content: center;
  }
}
.goalsLinkContainer {
  grid-area: link;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.enterInfoLink {
  text-align: center;
  padding-top: 2px;
  @media screen and (min-width: 672px) {
    padding-left: 8px;
    padding-top: 0;
  }
}
.notSureLink {
  padding-top: 37px;
}
.goalsTitle {
  max-width: 472px;
  margin: 0 auto 22px;
}
.formContainer {
  max-width: 500px;
  margin: 0 auto;
}
.jobExperienceRemoveButton {
  position: absolute !important;
  right: -12px;
  top: -12px;
  border: solid 2px $action-blue;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  font-size: 10px;
  padding: 0;
  background-color: $white;
  svg {
    font-size: 20.3px !important;
  }
  &:hover {
    cursor: pointer;
    background-color: $light-gray;
  }
  &CloseAbove {
    position: absolute !important;
    top: -40px;
    right: -8px;
  }
}
.jobExperienceItemsSeparator {
  margin-bottom: 0.75em;
  &CloseAbove {
    margin-bottom: 1.5em;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 1.5em;
  }
}
.jobContainer {
  border: solid 1px $mid-light-gray;
  padding: 8px;
  position: relative;
}
